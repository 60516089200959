<template>
    <div class="card pb-12">
        <header class="flex justify-between border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ paymentMethodName }}</h1>
            <el-button type="danger" :loading="$wait.is(paymentMethod.uuid + '.updating')" :disabled="!userCan('update settings')" @click="deletePaymentMethod">
                {{ $t('common.delete') }}
            </el-button>
        </header>
        <div class="px-16">
            <el-form ref="formData" label-position="top" :model="formData" :rules="rules">
                <template v-if="paymentMethod.type === 'transfer'">
                    <el-form-item :label="$t('settings.bank_name')" prop="bank_name">
                        <el-input v-model="formData.bank_name" />
                    </el-form-item>
                    <el-form-item :label="$t('settings.bank_account_nr')" prop="bank_account_nr">
                        <el-input v-model="formData.bank_account_nr" />
                    </el-form-item>
                </template>
                <template v-if="paymentMethod.type === 'autogiro'">
                    <el-form-item :label="$t('settings.autogiro_id')" prop="autogiro_id">
                        <el-input v-model="formData.autogiro_id" />
                    </el-form-item>
                    <el-form-item :label="$t('settings.autogiro_customer_id')" prop="autogiro_customer_id">
                        <el-input v-model="formData.autogiro_customer_id" />
                    </el-form-item>
                </template>
            </el-form>
            <div class="flex justify-end">
                <el-button :loading="$wait.is(paymentMethod.uuid + '.updating')" type="primary" @click="update">
                    {{ $t('common.update') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        paymentMethod: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            formData: {},
            rules:    {
                bank_name:            [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                bank_account_nr:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                autogiro_id:          [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
                autogiro_customer_id: [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
            },
        };
    },

    computed: {
        paymentMethodName() {
            if (this.paymentMethod.type === 'autogiro') {
                return 'Autogiro';
            }
            return this.$t('settings.bank_transfer');
        },
    },

    created() {
        this.bindData();
    },
    methods: {
        bindData() {
            this.formData = JSON.parse(JSON.stringify(this.paymentMethod));
        },

        async update() {
            this.$refs.formData.validate(async valid => {
                if (!valid) return false;

                this.$wait.start(`${this.paymentMethod.uuid}.updating`);

                try {
                    await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/settings/payment_methods/${this.paymentMethod.uuid}`, {
                        bank_name:            this.formData.paymentMethod === 'autogiro' ? null : this.formData.bank_name,
                        bank_account_nr:      this.formData.paymentMethod === 'autogiro' ? null : this.formData.bank_account_nr,
                        autogiro_id:          this.formData.paymentMethod === 'transfer' ? null : this.formData.autogiro_id,
                        autogiro_customer_id: this.formData.paymentMethod === 'transfer' ? null : this.formData.autogiro_customer_id,
                    });
                    this.$wait.end(`${this.paymentMethod.uuid}.updating`);
                    this.$notify.success({ title: this.$t('common.success') });
                } catch (error) {
                    this.$wait.end(`${this.paymentMethod.uuid}.updating`);
                }
            });
        },

        deletePaymentMethod() {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/settings/payment_methods/${this.paymentMethod.uuid}`);
                            this.$notify.success({ title: 'Success' });
                            this.$emit('refresh');
                        } catch {}

                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },
    },
};
</script>
